import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ConnectionFlow } from '../../../../components/images/support/xero/ConnectionFlow';
import { Screen1 } from '../../../../components/images/support/xero/Screen1.generated';
import { Screen2 } from '../../../../components/images/support/xero/Screen2.generated';
import { Screen3 } from '../../../../components/images/support/xero/Screen3.generated';
import { Screen4 } from '../../../../components/images/support/xero/Screen4.generated';
import { Screen5 } from '../../../../components/images/support/xero/Screen5.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "connecting-to-xero-"
    }}>{`Connecting to Xero 🔗`}</h1>
    <p>{`Canyou can connect with Xero to export your employees into Xero. Follow this guide to setup your connection.`}</p>

    <ConnectionFlow mdxType="ConnectionFlow" />
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the settings menu, then click the `}<inlineCode parentName="li">{`Connect to Xero`}</inlineCode>{` button.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Login to your Xero account`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Select your organisation and click continue`}</li>
    </ol>

    <Screen3 mdxType="Screen3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click the edit icon`}</li>
    </ol>

    <Screen4 mdxType="Screen4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click the drop down box, select the organisation you want to connect and click Update`}</li>
    </ol>

    <Screen5 mdxType="Screen5" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`You can now see that your organisation is connected to Xero. Great Job! 🥳`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      